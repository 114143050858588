<template>
    <div>
        <modal
        class="new-modal-default"
        :name="modal_name"
        transition="nice-modal-fade"
        :delay="100"
        :width="1000"
        :height="600"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        >
            <div class="v-modal-content" style="background-color: #ffffff !important;height: inherit;">
                <!-- <div class="v-modal-header" style="background-color: #fff !important;">
                    <div></div>
                    
                    <div>
                        <span class="icon icon-close mr-2 pointer close-left" style="color: #005dae !important;" @click="hideCreateEvent"></span>
                    </div>
                </div> -->
                <div class="v-modal-body">
                    <div style="margin-top: 5px !important;margin-bottom: 5px !important;">
                        <span v-if="edit_create == 'CREATE'" class="v-modal-dialog-title v-modal-title" style="color: #005dae !important;">Create New Event</span>
                        <span v-else class="v-modal-dialog-title v-modal-title" style="color: #005dae !important;">Edit Event</span>
                    </div>
                    <div class="v-modal-layout">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                
                                <!-- <p class="fw-600" style="font-size: 15px;">Location</p> -->
                                <div class="d-flex">
                                    <div class="gMap" style="position:relative;width:100%;">
                                        <gmap-autocomplete
                                            class="inputFieldNoBorder mt-0"
                                            id="autocompleteInput"
                                            placeholder="Search Location"
                                            @place_changed="setPlace"
                                            :value="locationVmodel"
                                            v-model="locationVmodel"
                                            style="padding: 8px 32px 8px 18px !important; height: 40px;"
                                        ></gmap-autocomplete>
                                        <img
                                            src="/static/images/marker_pin_new.svg"
                                            style="position: absolute;right: 12px;bottom: 12px;"
                                        />
                                    </div>
                                    <div class="form-group gMap ml-4 pt-0 m-0" style="cursor: pointer;" @click="getLocation">
                                        <img src="/static/images/gps_new.svg" style="height: 40px;" />
                                    </div>
                                </div>
                            </div>
                            <!-- <div style="min-width: 22em; max-width: 22em;">
                                <p class="fw-600" style="font-size: 15px;">Invite</p>
                                <div class="d-flex flex-column mt-6" style="position: relative;">
                                    <span>Invite Someone</span>
                                    <multiselect
                                        class="multiselect diginew-multiselect diginew-multiselect-gray greyblack event-mul-select-no-icon no-default-icon mt-1"
                                        placeholder="Invite"
                                        v-model="eventData.invitees"
                                        :multiple="true"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :options="inviteOptions"
                                        :loading="is_loading_invite"
                                        @open="getInvitees"
                                        @search-change="getInvitees"
                                        label="full_name"
                                        trackBy="id"
                                    >
                                        <template slot="caret">
                                            <div @mousedown.prevent.stop="toggle()" class="multiselect__select">
                                                <i class="icon-account-plus" style="color: #cdd2da;"></i>
                                            </div>
                                        </template> -->
                                        <!-- <template slot="option" slot-scope="props" >
                                            <span class="d-flex">
                                                {{ props.option.full_name ? props.option.full_name : props.option.email_id }}
                                            </span>
                                        </template>
                                        <template slot="afterList">
                                            <div v-observe-visibility="reachedEndOfListInvitees" />
                                        </template>
                                        <template slot="noOptions">
                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                        </template>
                                    </multiselect>
                                    <i class="icon-account-plus" style="color: #cdd2da; position: absolute; right: 0; margin-top: 30px; margin-right: 10px;"></i>
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                                <div class="d-flex align-items-center new-contactView" >
                                    <span class="fs-14 fw-600 text-secondary mt-2 profile-content-left-texts position-relative">Event Description</span>
                                    <input
                                        class="bg-transparent px-2 pb-2 mt-1"
                                        v-model="eventData.description"
                                        placeholder="Enter Text Here.."
                                        maxlength="100"
                                        name="description"
                                        style="border: 0;border-bottom: solid 1px #d7dae2 !important;text-transform: capitalize;color: #000000;width: 100%;"
                                    />  
                                </div>
                                <span class="editcharacter-count text-align-center text-grey float-right">{{100 - eventData.description.length}}/100</span>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12 mt-4">
                                <div class="d-flex align-items-center new-contactView">
                                    <span class="fs-14 fw-600 text-secondary mt-2 profile-content-left-texts position-relative">Event Name <span class="text-danger">*</span></span>
                                    <input
                                        class="bg-transparent px-2 pb-2 mt-1"
                                        v-model="eventData.name"
                                        placeholder="Enter Text Here.."
                                        @input="is_event_name = false"
                                        maxlength="100"
                                        name="event_name"
                                        style="border: 0;border-bottom: solid 1px #d7dae2 !important;text-transform: capitalize;color: #000000;width: 100%;"
                                    />
                                </div>
                                <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-if="is_event_name">Event Name is Required</span>
                                <span class="editcharacter-count text-grey float-right" style="right: 0;">{{100 - eventData.name.length}}/100</span>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                                <div class="d-flex align-items-center new-contactView">
                                    <span class="fs-14 fw-600 text-secondary mt-2 profile-content-left-texts position-relative">Event Type <span class="text-danger">*</span></span>
                                    <multiselect
                                        class="multiselect diginew-multiselect diginew-multiselect-gray no-default-icon down-arrow mt-1"
                                        placeholder="Select Event Type" label="value"
                                        v-model="eventData.event_type"
                                        :clearable="false" @open="getEventTypesData"
                                        :show-labels="false"
                                        :filterable="true"
                                        name="event_type"
                                        :options="event_type_options"
                                        v-validate="'required'"
                                    >
                                        <template slot="noOptions">
                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                        </template>
                                    </multiselect>
                                </div>
                                <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('event_type')">Event Type is Required</span>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <!-- <p class="fw-600" style="font-size: 15px;">Schedule <span class="text-danger">*</span></p> -->
                                <div class="d-flex align-items-center new-contactView">
                                    <span class="fs-14 fw-600 text-secondary mt-4 profile-content-left-texts position-relative">Start Date<span class="text-danger pl-1">*</span></span> 
                                    <date-picker
                                        class="mx-datepicker profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0"
                                        style="border-color: #d7dae2 !important;padding-left: 0px !important;"
                                        format="MM/DD/YYYY"
                                        v-model="eventData.event_schedule.start_date"
                                        :not-before="new Date()"
                                        :not-after="eventData.event_schedule.end_date"
                                        lang="en"
                                        @change="checkstartTime()"
                                        placeholder="MM/DD/YYYY"
                                        :clearable="false"
                                        v-validate="'required'"
                                        name="event_schedule_start_date"
                                        value-type="format"
                                        :editable="false"
                                        type="date"
                                        confirm
                                        confirm-text="APPLY"
                                    ></date-picker>
                                    <span class="fs-14 fw-600 text-secondary position-relative mt-4">Time<span class="text-danger pl-1">*</span></span>
                                    <el-select @change="endTime()" v-model="eventData.event_schedule.start_time" v-validate="'required'" class="input-field" name="event_schedule_start_time" style="border-color: #d7dae2 !important;margin-top: 8px;padding-bottom: 0px !important;padding-left: 0px !important;padding-right: 0px !important;" placeholder="Start Time">
                                        <el-option
                                            v-for="(item, index) in time_format"
                                            :key="index" 
                                            :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                        <!-- </div>
                                    </div> -->
                                        <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div v-if="!eventData.event_schedule.all_day">
                                                <span class="fs-14 fw-600 text-secondary mt-2 profile-content-left-texts position-relative">Start Time</span>
                                                <el-select v-model="eventData.event_schedule.start_time" class="input-field" style="padding: 0 !important;border-color: #d7dae2 !important;" placeholder="Start Time">
                                                    <el-option
                                                        v-for="(item, index) in time_format"
                                                        :key="index"
                                                        :label="item"
                                                        :value="item">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div> -->
                                    <!-- </div> -->
                                </div>
                                <div class="row">
                                    <div class="col-6 text-align-left">
                                        <span class="invalid-feedbacks-forms text-danger mt-0" v-show="errors.has('event_schedule_start_date')">Start Date is required.</span>
                                    </div>
                                    <div class="col-6 text-align-left">
                                        <span class="invalid-feedbacks-forms text-danger mt-0" v-show="errors.has('event_schedule_start_time')">Start Time is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <!-- <div class="d-flex justify-content-between flex-wrap">
                                    <div class="row">-->
                                    <div class="d-flex align-items-center new-contactView">
                                        <span class="fs-14 fw-600 text-secondary mt-4 profile-content-left-texts position-relative">End Date<span class="text-danger pl-1">*</span></span>
                                            <date-picker
                                                class="mx-datepicker profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0 mt-1"
                                                style="border-color: #d7dae2 !important;padding-left: 0px !important;"
                                                format="MM/DD/YYYY"
                                                v-model="eventData.event_schedule.end_date"
                                                :not-before="eventData.event_schedule.start_date"
                                                lang="en"
                                                placeholder="MM/DD/YYYY"
                                                v-validate="'required'"
                                                name="event_schedule_end_date"
                                                :clearable="false"
                                                :editable="false"
                                                value-type="format"
                                                type="date"
                                                confirm
                                                confirm-text="APPLY"
                                            ></date-picker>
                                            
                                            <span class="fs-14 fw-600 text-secondary position-relative mt-4">Time<span class="text-danger pl-1">*</span></span>
                                                <el-select v-if="eventData.event_schedule.start_date == eventData.event_schedule.end_date" v-model="eventData.event_schedule.end_time" v-validate="'required'" name="event_schedule_end_time" class="input-field" style=" border-color: #d7dae2 !important;margin-top: 8px;padding-left: 0px !important;padding-right: 0px !important;" placeholder="End Time">
                                                    <el-option
                                                        v-for="(item, index) in time_format" :disabled="eventData.event_schedule.start_time >= item"
                                                        :key="index"
                                                        :label="item" style="padding-bottom: 0px !important;"
                                                        :value="item">
                                                    </el-option>
                                                </el-select>
                                                <el-select v-else v-model="eventData.event_schedule.end_time" v-validate="'required'" name="event_schedule_end_time" class="input-field" style=" border-color: #d7dae2 !important;margin-top: 8px;padding-left: 0px !important;padding-right: 0px !important;" placeholder="End Time">
                                                    <el-option
                                                        v-for="(item, index) in time_format"
                                                        :key="index"
                                                        :label="item" style="padding-bottom: 0px !important;"
                                                        :value="item">
                                                    </el-option>
                                                </el-select>
                                        <!-- </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6"> -->
                                            <!-- <div v-if="!eventData.event_schedule.all_day" class="mt-4" style="width: 45%;">
                                                <span>End Time</span>
                                                <el-select v-model="eventData.event_schedule.end_time" class="input-field" style="padding: 0 !important; border-color: #d7dae2 !important;" placeholder="End Time">
                                                    <el-option
                                                        v-for="(item, index) in time_format"
                                                        :key="index"
                                                        :label="item"
                                                        :value="item">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>-->
                                    </div> 
                                    <div class="row">
                                        <div class="col-6 text-align-center">
                                            <span class="invalid-feedbacks-forms text-danger mt-0" v-show="errors.has('event_schedule_end_date')">End Date is required.</span>
                                        </div>
                                        <div class="col-6 text-align-left">
                                            <span class="invalid-feedbacks-forms text-danger mt-0" v-show="errors.has('event_schedule_end_time')">End Time is required.</span>
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex w-100 mt-8">
                                        <input v-model="eventData.event_schedule.all_day" type="checkbox" />
                                        <span class="ml-2">All Day</span>
                                    </div> -->
                                <!-- </div> -->
                            </div>
                        </div>
                        <div class="row mt-6">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <!-- <p class="fw-600" style="font-size: 15px;">Repeat</p> -->
                                <div class="d-flex align-items-center new-contactView">
                                    <span class="fs-14 fw-600 text-secondary mt-2 profile-content-left-texts position-relative">Repeat<span class="text-danger pl-1">*</span></span>
                                    <multiselect
                                        class="multiselect diginew-multiselect diginew-multiselect-gray text-capitalize no-default-icon down-arrow"
                                        v-model="event_repeat_option" name="repeat_data"
                                        :clearable="false" v-validate="'required'"
                                        :show-labels="false" style="padding-bottom: 0px !important;"
                                        :filterable="true" width="100% !important"
                                        :options="['Do Not Repeat', 'Daily', 'Weekly', 'Monthly', 'Yearly']"
                                        >
                                        <template slot="noOptions">
                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                        </template>
                                    </multiselect>
                                </div>
                                <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('repeat_data')">Repeat is Required</span>
                                <div v-if="event_repeat_option == 'Weekly'" class="d-flex align-items-right flex-wrap mt-2 padding-left">
                                    <div class="d-flex align-items-center mt-2 mr-2">
                                        <input type="checkbox" id="mon" name="mon" v-model="repeat_day_mon">
                                        <label style="font-size: 14px;" class="mb-0 ml-2" :class="{'text-secondary':repeat_day_mon}" for="mon">Mon</label>
                                    </div>
                                    <div class="d-flex align-items-center mt-2 mr-2">
                                        <input type="checkbox" id="tue" name="tue" v-model="repeat_day_tue">
                                        <label style="font-size: 14px;" class="mb-0 ml-2" :class="{'text-secondary':repeat_day_tue}" for="tue">Tue</label>
                                    </div>
                                    <div class="d-flex align-items-center mt-2 mr-2">
                                        <input type="checkbox" id="wed" name="wed" v-model="repeat_day_wed">
                                        <label style="font-size: 14px;" class="mb-0 ml-2" :class="{'text-secondary':repeat_day_wed}" for="wed">Wed</label>
                                    </div>
                                    <div class="d-flex align-items-center mt-2 mr-2">
                                        <input type="checkbox" id="thu" name="thu" v-model="repeat_day_thu">
                                        <label style="font-size: 14px;" class="mb-0 ml-2" :class="{'text-secondary':repeat_day_thu}" for="thu">Thu</label>
                                    </div>
                                    <div class="d-flex align-items-center mt-2 mr-2">
                                        <input type="checkbox" id="fri" name="fri" v-model="repeat_day_fri">
                                        <label style="font-size: 14px;" class="mb-0 ml-2" :class="{'text-secondary':repeat_day_fri}" for="fri">Fri</label>
                                    </div>
                                    <div class="d-flex align-items-center mt-2 mr-2">
                                        <input type="checkbox" id="sat" name="sat" v-model="repeat_day_sat">
                                        <label style="font-size: 14px;" class="mb-0 ml-2" :class="{'text-secondary':repeat_day_sat}" for="sat">Sat</label>
                                    </div>
                                    <div class="d-flex align-items-center mt-2 mr-2">
                                        <input type="checkbox" id="sun" name="sun" v-model="repeat_day_sun">
                                        <label style="font-size: 14px;" class="mb-0 ml-2" :class="{'text-secondary':repeat_day_sun}" for="sun">Sun</label>
                                    </div>
                                </div>
                                <div class="mt-4 padding-left" v-if="event_repeat_option != 'Do Not Repeat' && event_repeat_option != ''">
                                    <!-- <div class="mb-1">End Date</div> -->
                                    <div class="">
                                        <div class="d-flex align-items-center mr-3">
                                            <div class="d-flex align-items-center">
                                                <input type="radio" id="repeat_never" name="repeat_never_date" v-model="repeat_never_date" value="never">
                                                <label class="mb-0 ml-2" for="repeat_never">Never</label>
                                            </div>
                                            <div class="d-flex align-items-center ml-3">
                                                <input type="radio" id="repeat_date" name="repeat_never_date" v-model="repeat_never_date" value="date">
                                                <label class="mb-0 ml-2" for="repeat_date">Select Date</label>
                                            </div>
                                        </div>
                                        <div v-if="repeat_never_date == 'date'">
                                            <!-- <div class="mt-2">Select End Date</div> -->
                                            <date-picker
                                                class="fullBorderCalendar mx-left calendar-edit-per mt-1"
                                                format="MM/DD/YYYY"
                                                v-model="eventData.repeat.end_date"
                                                :not-before="new Date()"
                                                lang="en"
                                                value-type="format"
                                                :clearable="false"
                                                :editable="false"
                                                confirm
                                                confirm-text="APPLY" style="border: 1px solid #ccc !important; border-radius: 6px !important;"
                                            ></date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <!-- <p class="fw-600" style="font-size: 15px;">Set Reminder</p> -->
                                <div>
                                    <div>
                                        <div class="d-flex align-items-center mt-1" v-for="(reminder,index) in eventData.remind_before" :key="index">
                                            <span class="fs-14 fw-600 text-secondary mt-2 position-relative" style="width: 200px !important;" v-if="event_repeat_option == 'Do Not Repeat'">Set Reminder</span>
                                            <span class="fs-14 fw-600 text-secondary mt-2 position-relative d-flex" style="width: 200px !important;" v-else>Set Reminder <span class="text-danger">*</span></span>
                                            <input class="text-center mx-2 pr-5" v-model="reminder.number" v-if="event_repeat_option == 'Do Not Repeat'" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" style="min-height: 40px; width: 55px; border: solid 1px #d7dae2; border-radius: 5px;padding-left: 25px;" />
                                            <input class="text-center mx-2 pr-5" v-model="reminder.number" v-else v-validate="'required'" name="before_repeated_number" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" style="min-height: 40px; width: 55px; border: solid 1px #d7dae2; border-radius: 5px;padding-left: 25px;" />
                                            <div class="d-flex flex-column" style="position: absolute; margin-left: 125px;">
                                                <i class="icon icon-arrow-down-drop pointer" style="transform: rotate(180deg); line-height: 12px;" @click="reminder.number++"></i>
                                                <i class="icon icon-arrow-down-drop pointer" style="line-height: 12px;" @click="reminder.number != 0 ? reminder.number-- : ''"></i>
                                            </div>
                                            <multiselect v-if="event_repeat_option == 'Do Not Repeat'"
                                                class="multiselect diginew-multiselect diginew-multiselect-gray no-default-icon create-event down-arrow"
                                                v-model="reminder.gap"
                                                :clearable="false"
                                                :filterable="true"
                                                :show-labels="false"
                                                :options="['Days', 'Minutes', 'Hours']"
                                            >
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                            </multiselect>
                                            <multiselect v-else
                                                class="multiselect diginew-multiselect diginew-multiselect-gray no-default-icon create-event down-arrow"
                                                v-model="reminder.gap"
                                                v-validate="'required'"
                                                :clearable="false"
                                                name="before_repeat"
                                                :filterable="true"
                                                :show-labels="false"
                                                :options="['Days', 'Minutes', 'Hours']"
                                            >
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                            </multiselect>
                                            <!-- <i v-if="index != 0" class="icon-minus-circle ml-1" style="color: #e82828; font-size: 16px;" @click="deleteRemindBefore(index)"></i>
                                            <i class="icon-plus-circle ml-1" :class="index == 0 && eventData.remind_before.length > 1 ? 'ml-6' : 'ml-0'" style="color: #0475f3; font-size: 16px;" @click="addRemindBefore"></i> -->
                                        </div>
                                        <span class="invalid-feedback-form text-danger" style="text-transform: none !important;padding-left: 125px;" v-if="errors.has('before_repeat') || errors.has('before_repeated_number')">Set Reminder is Required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <!-- <p class="fw-600" style="font-size: 15px;">Invite</p> -->
                                <div class="d-flex align-items-center new-contactView">
                                    <span class="fs-14 fw-600 text-secondary mt-2 profile-content-left-texts position-relative">Add Guest</span>
                                    <multiselect
                                        class="multiselect diginew-multiselect diginew-multiselect-gray greyblack event-mul-select-no-icon no-default-icon mt-1"
                                        placeholder="Add Guest"
                                        v-model="eventData.invitees"
                                        :multiple="true"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :options="inviteOptions"
                                        :loading="is_loading_invite"
                                        :limit="10"
                                        openDirection="bottom"
                                        @search-change="getInvitees"
                                        label="full_name"
                                        trackBy="id" width="100% !important"
                                        
                                    >
                                        <!-- <template slot="caret">
                                            <div @mousedown.prevent.stop="toggle()" class="multiselect__select">
                                                <i class="icon-account-plus" style="color: #cdd2da;"></i>
                                            </div>
                                        </template> -->
                                        <template slot="option" slot-scope="props" >
                                            <span class="d-flex">
                                                {{ props.option.full_name ? props.option.full_name : props.option.email_id }}
                                            </span>
                                        </template>
                                        <template slot="afterList">
                                            <div v-observe-visibility="reachedEndOfListInvitees" />
                                        </template>
                                        <template slot="noOptions">
                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                        </template>
                                    </multiselect>
                                    <!-- <span class="mt-4">
                                    <button class="btn btn-new-guest btn-smm mr-3" style="min-width: 10.8rem !important;" @click="inviteOptions">Add Guest</button></span> -->
                                    <!-- <button class="btn" style="border: 1px solid #3195f2;padding-right: 20px !important;">Add Guest</button> -->
                                    <!-- <i class="icon-account-plus" style="color: #cdd2da; position: absolute; right: 0; margin-top: 30px; margin-right: 10px;"></i> -->
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions">
                    <div class="d-flex justify-content-center align-items-center my-6">
                        <button class="btn btn-new-danger btn-smm mr-3" :disabled="save_create_btn_hide" @click="hideCreateEvent" style="width: 120px !important;">Cancel</button>
                        <button class="btn btn-new-green btn-smm ml-3" :disabled="save_create_btn_hide" @click="saveCreateEvent" style="width: 120px !important;">Create Event
                            <hollow-dots-spinner v-if="save_create_btn_hide" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -35px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                        </button>
                    </div>
                </div>
            </div>
        </modal>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>

<script>
import moment from 'moment';
import { HollowDotsSpinner } from "epic-spinners";
import VueTextareaAutosize from "vue-textarea-autosize";
import axios from "axios";
import globals from '../globals';
import profile from '../mixins/profile';
import { SweetModal } from 'sweet-modal-vue';
import settings from '../mixins/settings';
export default {
    data() {
        const value2date = (dateRange) => {
            const date = new Date(dateRange)
            return dateRange ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000) : null
        }
        const date2value = (date) => {
            return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000) : null
        }
        return {
            valueType: {
                value2date,
                date2value
            },
            warning_msg: '',
            success_msg: '',
            eventData: {
                description: "",
                event_schedule: {
                    all_day: false,
                    end_date: "",
                    start_date: "",
                    end_time: "",
                    start_time: "",
                },
                event_type: "",
                invitees: [],
                location: {
                    city: "",
                    country: "",
                },
                name: "",
                remind_before: [
                    {
                        gap: "",
                        number: 0,
                    },
                ],
                repeat: {
                    end_date: "",
                },
            },
            time_format: [
                '00:00',
                '00:30',
                '01:00',
                '01:30',
                '02:00',
                '02:30',
                '03:00',
                '03:30',
                '04:00',
                '04:30',
                '05:00',
                '05:30',
                '06:00',
                '06:30',
                '07:00',
                '07:30',
                '08:00',
                '08:30',
                '09:00',
                '09:30',
                '10:00',
                '10:30',
                '11:00',
                '11:30',
                '12:00',
                '12:30',
                '13:00',
                '13:30',
                '14:00',
                '14:30',
                '15:00',
                '15:30',
                '16:00',
                '16:30',
                '17:00',
                '17:30',
                '18:00',
                '18:30',
                '19:00',
                '19:30',
                '20:00',
                '20:30',
                '21:00',
                '21:30',
                '22:00',
                '22:30',
                '23:00',
                '23:30',
            ],
            locationVmodel: "",
            inviteOptions: [],
            is_loading_invite:false,
            search_key: "",
            is_event_name: false,
            event_repeat_option: '',
            repeat_day_mon: false,
            repeat_day_tue: false,
            repeat_day_wed: false,
            repeat_day_thu: false,
            repeat_day_fri: false,
            repeat_day_sat: false,
            repeat_day_sun: false,
            repeat_never_date: 'date',
            save_create_btn_hide: false,
            isVisible: false,
            scrollable: false,
            count_invite: 0,
            temp_dropdown_options: [],
            event_type_options: [],
        }
    },
    props: ["modal_name", "edit_create", "event_id"],
    components: {
        VueTextareaAutosize,
        SweetModal,
        HollowDotsSpinner
    },
    mixins: [profile, settings],
    methods: {
        checkstartTime(){
            var curr_date = moment(new Date().toUTCString()).format("MM/DD/YYYY")
            this.eventData.event_schedule.start_time = "";
            this.eventData.event_schedule.end_date = "";
            this.eventData.event_schedule.end_time = "";
            this.time_format = [
                '00:00',
                '00:15',
                '00:30',
                '00:45',
                '01:00',
                '01:15',
                '01:30',
                '01:45',
                '02:00',
                '02:15',
                '02:30',
                '02:45',
                '03:00',
                '03:15',
                '03:30',
                '03:45',
                '04:00',
                '04:15',
                '04:30',
                '04:45',
                '05:00',
                '05:15',
                '05:30',
                '05:45',
                '06:00',
                '06:15',
                '06:30',
                '06:45',
                '07:00',
                '07:15',
                '07:30',
                '07:45',
                '08:00',
                '08:15',
                '08:30',
                '08:45',
                '09:00',
                '09:15',
                '09:30',
                '09:45',
                '10:00',
                '10:15',
                '10:30',
                '10:45',
                '11:00',
                '11:15',
                '11:30',
                '11:45',
                '12:00',
                '12:15',
                '12:30',
                '12:45',
                '13:00',
                '13:15',
                '13:30',
                '13:45',
                '14:00',
                '14:15',
                '14:30',
                '14:45',
                '15:00',
                '15:15',
                '15:30',
                '15:45',
                '16:00',
                '16:15',
                '16:30',
                '16:45',
                '17:00',
                '17:15',
                '17:30',
                '17:45',
                '18:00',
                '18:15',
                '18:30',
                '18:45',
                '19:00',
                '19:15',
                '19:30',
                '19:45',
                '20:00',
                '20:15',
                '20:30',
                '20:45',
                '21:00',
                '21:15',
                '21:30',
                '21:45',
                '22:00',
                '22:15',
                '22:30',
                '22:45',
                '23:00',
                '23:15',
                '23:30',
                '23:45',
            ]
            if(this.eventData.event_schedule.start_date == curr_date){
                var temp_start_time = ""
                var curr_time = moment(new Date().toUTCString()).format("HH:mm");
                if(parseInt(curr_time.substring(3)) >= 0 && parseInt(curr_time.substring(3)) < 15){
                    temp_start_time = curr_time.slice(0,3) + '00'
                } else if(parseInt(curr_time.substring(3)) >= 15 && parseInt(curr_time.substring(3)) < 30){
                    temp_start_time = curr_time.slice(0,3) + '15'
                } else if(parseInt(curr_time.substring(3)) >= 30 && parseInt(curr_time.substring(3)) < 45){
                    temp_start_time = curr_time.slice(0,3) + '30'
                } else if(parseInt(curr_time.substring(3)) >= 45){
                    temp_start_time = curr_time.slice(0,3) + '45'
                }
                let indx = this.time_format.indexOf(temp_start_time)
                this.time_format.splice(0,indx+1)
            }
        },
        endTime(){
            if(this.eventData.event_schedule.start_time >= this.eventData.event_schedule.end_time)
            {
                this.eventData.event_schedule.end_time = '';
            }
        },
        clearAll() {
            this.eventData = {
                description: "",
                event_schedule: {
                    all_day: false,
                    end_date: "",
                    start_date: "",
                },
                event_type: "",
                invitees: [],
                location: {
                    city: "",
                    country: "",
                },
                name: "",
                remind_before: [
                    {
                        gap: "",
                        number: 0,
                    },
                ],
                repeat: {
                    end_date: "",
                },
            };
            this.locationVmodel = "";
            this.inviteOptions = [];
            this.search_key = "";
        },
        setPlace(place) {
            this.locationVmodel = place.formatted_address;
            let address = place.formatted_address.split(", ");
            this.eventData.location.city = address[address.length - 3];
            this.eventData.location.country = address[address.length - 1];
        },
        checkName(){
            if(this.eventData.name){
                this.is_event_name = false
            }
            else{
                this.is_event_name = true
            }
        },
        getLocation() {
            if (navigator.geolocation) {
                // timeout at 60000 milliseconds (60 seconds)
                var options = {
                enableHighAccuracy: false,
                timeout: 30000, // milliseconds (30 seconds)
                maximumAge: 600000
                }; // milliseconds (10 minutes)
                navigator.geolocation.getCurrentPosition(
                this.showLocation,
                this.errorHandler,
                options
                );
            } else {
                alert("Sorry, browser does not support geolocation!");
            }
        },
        showLocation(position) {
            let latlongvalue = position.coords.latitude + "," + position.coords.longitude;
            let calllatlong = axios.create();
            delete calllatlong.defaults.headers.common['Authorization'];
            calllatlong.post("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlongvalue + "&key=AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY")
            .then(response => {
                let address = response.data.results[0].formatted_address.split(", ");
                this.eventData.location.city = address[address.length - 3];
                this.eventData.location.country = address[address.length - 1];
                this.locationVmodel = response.data.results[0].formatted_address;
            }).catch(e => {
                // this.errors.push(e)
            });
        },
        errorHandler(err) {
            if (err.code == 1) {
                // alert("Error: Access is denied!");
            } else if (err.code == 2) {
                // alert("Error: Position is unavailable!");
            }
        },
        addRemindBefore() {
            this.eventData.remind_before.push({
                gap: "",
                number: 0,
            });
        },
        deleteRemindBefore(index) {
            this.eventData.remind_before.splice(index,1);
        },
        async getInvitees(key) {
            this.inviteOptions = [];
            this.is_loading_invite = true
            if (key) {
                this.search_key = key;
            }
            let params = {
                limit: 10,
                skip: 0,
                search_key: this.search_key,
            };
            let res = await this.$http.get(globals.AUTH_SERVICE + `/profile/users/dropdown?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}`);
            if (res.data.status_id == 1) {
                this.inviteOptions = res.data.response;
                this.is_loading_invite = false
                this.count_invite = res.data.response.length;
            }
        },
        async reachedEndOfListInvitees(reached) {
            this.isVisible = reached;
            if (reached) {
                if (this.scrollable == false) {
                    this.is_loading_invite = true;
                    let params = {
                        limit: 10,
                        search_key: this.search_key,
                        skip: this.count_invite
                    };
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/profile/users/dropdown?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}`);
                    this.temp_dropdown_options = response.data.response.length;;
                    if (response.data.skip == this.count_invite) {
                        if (this.temp_dropdown_options > 0) {
                            let tempFiles = response.data.response;
                            tempFiles.forEach(x => {
                                this.inviteOptions.push(x);
                            });
                            this.count_invite += response.data.response.length;
                            this.is_loading_invite = false
                        } else {
                            this.scrollable = true
                            this.is_loading_invite = false
                        }
                    }
                    this.is_loading_invite = false
                }
            }
            // if (reached && this.inviteOptions.length >= 10) {
            //     this.is_loading_invite = true
            //     let params = {
            //         limit: 10,
            //         skip: this.inviteOptions.length,
            //         search_key: this.search_key,
            //     };
            //     let res = await this.$http.get(globals.AUTH_SERVICE + `/profile/users/dropdown?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}`);
            //     if (res.data.status_id == 1) {
            //         res.data.response.forEach(x => {
            //             this.inviteOptions.push(x);
            //         });
            //         this.is_loading_invite = false
            //     }
            // }
        },
        hideCreateEvent() {
            // this.clearAll();
            // setTimeout(() => {
            //     this.$modal.hide(this.modal_name);
            // }, 500);
            this.$emit("hideCreateEvent");
        },
        async saveCreateEventSuccess() {
            try {
                this.save_create_btn_hide = true;
                let params = this.createEventJson;
                // if (!params.end_date && !params.start_date && !params.all_day) {
                //     params.repeat.end_date = '';
                //     params.repeat.start_date = '';
                // }
                params.start_date = this.createEventJson.start_date.split('/')[2]+'-'+this.createEventJson.start_date.split('/')[0]+'-'+this.createEventJson.start_date.split('/')[1]
                params.end_date = this.createEventJson.end_date.split('/')[2]+'-'+this.createEventJson.end_date.split('/')[0]+'-'+this.createEventJson.end_date.split('/')[1]
                if (params.repeat.repeat_option != 'Weekly') {
                    params.repeat.options = [];
                } else {
                    if(this.repeat_day_mon) {
                        params.repeat.options.push('Mon');
                    } 
                    if(this.repeat_day_tue) {
                        params.repeat.options.push('Tue');
                    } 
                    if(this.repeat_day_wed) {
                        params.repeat.options.push('Wed');
                    } 
                    if(this.repeat_day_thu) {
                        params.repeat.options.push('Thu');
                    } 
                    if(this.repeat_day_fri) {
                        params.repeat.options.push('Fri');
                    } 
                    if(this.repeat_day_sat) {
                        params.repeat.options.push('Sat');
                    } 
                    if(this.repeat_day_sun) {
                        params.repeat.options.push('Sun');
                    }
                }
                if (params.reminder.length == 1 && !params.reminder[0].gap) {
                    params.reminder = [];
                }
                if(params.repeat.repeat_option == 'Do Not Repeat') {
                    delete params.repeat.end_date;
                    delete params.repeat.never;
                    delete params.repeat.options;
                }
                if(params.repeat.never == true && (params.repeat.repeat_option == 'Daily' || params.repeat.repeat_option == 'Monthly' || params.repeat.repeat_option == 'Yearly')) {
                    delete params.repeat.end_date;
                    delete params.repeat.options;
                }
                if(params.repeat.never == false && (params.repeat.repeat_option == 'Daily' || params.repeat.repeat_option == 'Monthly' || params.repeat.repeat_option == 'Yearly')) {
                    delete params.repeat.options;
                    params.repeat.end_date = this.createEventJson.repeat.end_date ? this.createEventJson.repeat.end_date.split('/')[2]+'-'+this.createEventJson.repeat.end_date.split('/')[0]+'-'+this.createEventJson.repeat.end_date.split('/')[1] : ''
                }
                if(params.repeat.repeat_option == 'Weekly' && params.repeat.never == true) {
                    delete params.repeat.end_date;
                }
                if(this.createEventJson.all_day == true) {
                    params.start_time = '00:00';
                    params.end_time = '00:00';
                }
                params.invitees = [...new Set(this.eventData.invitees.map(x => x.id))];
                if(this.event_id.length) {
                    params.id = this.event_id;
                    params.user_id = this.loggedInUser.user_id;
                    let res = await this.updateEvents(params, this.event_id);
                    if (res.status_id == 1) {
                        this.success_msg = res.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.$modal.hide(this.modal_name);
                            this.save_create_btn_hide = false;
                            this.$emit('eventCreatedSuccess');
                        }, 2000);
                    }
                } else {
                    delete params.id
                    this.save_create_btn_hide = false;
                    delete params.user_id
                    let res = await this.createEvents(params);
                    // this.clearAll();
                    if (res.status_id == 1) {
                        this.success_msg = res.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.$modal.hide(this.modal_name);
                            this.$emit('eventCreatedSuccess');
                        }, 2000);
                    }
                }
            }
            catch(err) {
                this.save_create_btn_hide = false;
                this.warning_msg = err;
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                }, 3000);
            }
        },
        async saveCreateEvent() {
            this.save_create_btn_hide = true
            this.$validator.validateAll().then( async result => {
                if(this.eventData.name == '') {
                    this.is_event_name = true
                }
                else {
                    this.is_event_name = false
                }
                if (result && this.is_event_name == false) {
                    this.saveCreateEventSuccess()
                }
                else {
                    
                }
            })
            this.save_create_btn_hide = false
        },
        async getEventDataById() {
            try {
                let param = this.event_id;
                let response = await this.getEventsById(param);
                if(response.status_id == 1) {
                    let events_data = response.response;
                    this.eventData.event_schedule.start_date = events_data.start_date ? events_data.start_date.split('-')[1]+'/'+events_data.start_date.split('-')[2]+'/'+events_data.start_date.split('-')[0] : '';
                    this.eventData.event_schedule.end_date = events_data.end_date ? events_data.end_date.split('-')[1]+'/'+events_data.end_date.split('-')[2]+'/'+events_data.end_date.split('-')[0] : '';
                    this.eventData.event_schedule.start_time = events_data.start_time;
                    this.eventData.event_schedule.end_time = events_data.end_time;
                    this.eventData.name = events_data.event_name;
                    // this.eventData.id = events_data.id;
                    // this.eventData.id = events_data.id;
                    this.eventData.event_type = {
                        value: events_data.event_type
                    };
                    this.eventData.description = events_data.description;
                    this.eventData.event_schedule.all_day = events_data.all_day;
                    this.locationVmodel = events_data.location;
                    this.eventData.invitees = events_data.invitees;
                    this.eventData.repeat.end_date = events_data.repeat.end_date ? events_data.repeat.end_date.split('-')[1]+'/'+events_data.repeat.end_date.split('-')[2]+'/'+events_data.repeat.end_date.split('-')[0] : '';
                    this.event_repeat_option = events_data.repeat.repeat_option;
                    this.repeat_never_date = events_data.repeat.never ? 'never' : 'date';
                    if(events_data.reminder.length > 0) {
                        this.eventData.remind_before = events_data.reminder;
                    }
                    if(events_data.repeat.options.length) {
                        events_data.repeat.options.forEach(el=>{
                            if(el == 'Mon' || el == 'Monday') {
                                this.repeat_day_mon = true;
                            } else if(el == 'Tue' || el == 'Tuesday') {
                                this.repeat_day_tue = true;
                            } else if(el == 'Wed' || el == 'Wednesday') {
                                this.repeat_day_wed = true;
                            } else if(el == 'Thu' || el == 'Thursday') {
                                this.repeat_day_thu = true;
                            } else if(el == 'Sat' || el == 'Saturday') {
                                this.repeat_day_sat = true;
                            } else if(el == 'Sun' || el == 'Sunday') {
                                this.repeat_day_sun = true;
                            }
                        });
                    }
                }
            }
            catch(err) {
                // console.log(err);
            }
        },
        async getEventTypesData() {
            try {
                let params = {
                    type: 'event'
                }
                let response = await this.getListSettings(params);
                if (response.status_id == 1) {
                    this.event_type_options = response.response.event;
                }
            }
            catch(err) {
                console.log(err)
            }
        }
    },
    mounted() {
        if(this.event_id.length && this.edit_create == 'EDIT') {
            this.getEventDataById();
        }
    },
    watch: {
        "eventData.all_day": function() {
            if (this.eventData.all_day) {
                this.eventData.start_date = new Date();
                this.eventData.end_date = new Date();
            }
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        // account() {
        //     return this.$store.getters.account;
        // },
        // selectedCompany() {
        //     return this.$store.getters.selectedCompany;
        // },
        createEventJson() {
            return {
                reminder: this.eventData.remind_before,
                repeat: {
                    repeat_option: this.event_repeat_option,
                    never: this.repeat_never_date == 'date' ? false : true,
                    end_date: this.eventData.repeat.end_date,
                    options: []
                },
                event_name: this.eventData.name,
                event_type: this.eventData?.event_type?.value || '',
                description: this.eventData.description,
                start_date: this.eventData.event_schedule.start_date,
                end_date: this.eventData.event_schedule.end_date,
                start_time: this.eventData.event_schedule.start_time,
                end_time: this.eventData.event_schedule.end_time,
                location: this.eventData.location.city ? this.eventData.location.city+','+this.eventData.location.country : '',
                all_day: this.eventData.event_schedule.all_day,
                invitees: [],
                id: '',
                user_id: ''
            }
        }
    },
    created() {
        const dict = {
            custom: {
                
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>
<style scoped>
    @media only screen and (min-width:769px){
        .padding-left{
            padding-left: 150px;
        }
        .invalid-feedback-form{
            padding-left: 150px;
        }
        .invalid-feedback-forms{
            padding-left: 100px;
        }
        .invalid-feedbacks-forms{
            padding-left: 78px;
        }
    }
    .profile-content-left-texts {
        width: 150px !important;
        min-width: 150px !important;
        position: relative;
        font-weight: 600;
    }
    .btn-new-guest {
        color: #00448b !important;
        background-color: #fff !important;
        border-color: #00448b !important;
        border: 1px solid #00448b !important;
        box-shadow: none !important;
        font-weight: 500;
    }
    .btn-new-danger {
        color: #e82828 !important;
        background-color: #fff !important;
        border-color: #e82828 !important;
        border: 1px solid #e82828 !important;
        box-shadow: none !important;
        font-weight: 500;
    }
    .btn-new-danger:hover {
        color: #fff !important;
        background-color: #e82828 !important;
        border-color: #e82828 !important;
        box-shadow: none !important;
        font-weight: 500;
    }
    .btn-smm{
        border-radius: 6px !important;
    }
	.v-modal-header {
		display: -webkit-box;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 15px;
		border-bottom: 0px solid #E9ECEF;
		-webkit-border-top-left-radius: 15px;
		border-top-left-radius: 15px;
		-webkit-border-top-right-radius: 15px;
		border-top-right-radius: 15px;
		background-color: #f5f6f9 !important;
	}

	.v-modal-content {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
		background-color: #fff;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		border: 0px solid rgba(0, 0, 0, 0.2);
		-webkit-border-radius: 2px;
		border-radius: 2px;
		-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
		outline: 0;
	}

	.v-modal-dialog-title.v-modal-title {
		letter-spacing: .005em;
	}

	.v-modal-dialog-title {
		margin-bottom: 0px !important;
		font-size: 15px !important;
		font-weight: 600 !important;
		font-style: normal;
		display: block;
		font-stretch: normal;
		line-height: normal;
		color: #2b6ad0 !important;
		text-transform: uppercase !important;
		margin: 0 auto !important;
		padding: 4px !important;
	}

	.v-modal-body {
		overflow: auto;
		width: 100%;
		position: relative;
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		padding: 15px;
	}

	.new-modal-default .v-modal-header {
		background-color: #005dae !important;
		height: 50px;
        color: #fff;
        display: flex;
	}

    .v-modal-dialog-title {
        color: #fff !important;
    }

	/* .v-modal-layout {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		} */
	.v-modal-dialog-actions {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 15px;
		border-top: 0px solid transparent;
	}
    .diginew-multiselect, .input-field, .input-border, .vue-tel-input 
    {
        border-radius: 0px !important;
        border: solid 0px #fff !important;
        border-bottom: 1px solid rgb(215, 218, 226) !important;
        background-color: #fff;
        padding:12px 10px !important;
        width: 100% !important;
        height: 41px !important;
    }
    ::placeholder{
        color: #adadad !important;
        font-weight: 400 !important;
    }
    .multiselect.diginew-multiselect .multiselect__select{
        top: 4px !important;
    }
    .mx-input {
		display: inline-block;
		width: 100%;
		height: 28px !important;
		padding: 6px 30px;
	}
    .multiselect__tag-icon {
		cursor: pointer;
		margin-left: 7px;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		font-weight: 700;
		font-style: normal;
		width: 22px;
		text-align: center;
		line-height: 15px;
		transition: all .2s ease;
		border-radius: 5px;
	}
</style>